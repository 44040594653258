
import { Agreement, Building } from "@/interfaces";
import Vue, { PropType } from "vue";
import MyDetail from "@/components/property/MyDetail.vue";
import MyMemo from "@/components/property/MyMemo.vue";

import { mdiLink, mdiDotsVertical } from "@mdi/js";

import http from "@/http";

export interface DataType {
  hasProperty: boolean;
}

export default Vue.extend({
  components: { MyDetail, MyMemo },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  data(): DataType {
    return {
      hasProperty: true,
    };
  },
  computed: {
    agreements(): Agreement[] {
      return this.building.agreements.filter((agreement) => {
        if (this.hasProperty) {
          return !!agreement.property;
        } else {
          return agreement.owner != null;
        }
      });
    },
    icon() {
      return { mdiLink, mdiDotsVertical };
    },
  },
  methods: {
    async remove(agreement: Agreement) {
      if (!window.confirm("本当に削除しますか?")) {
        return;
      }
      const url = `agreements/${agreement.id}`;

      await http.delete(url);

      this.building.agreements = this.building.agreements.filter((item) => {
        return item.id !== agreement.id;
      });
    },
    click(agreement: Agreement) {
      const url = `http://172.16.0.2:8080/spweb/common_window/ref_tty_readonly.asp?nTtyId=${this.building.id}&nKrmId=${agreement.id}`;
      window.open(url);
    },
  },
});


import Vue, { PropType } from "vue";

import { Agreement, Memo } from "@/interfaces";
import dayjs from "dayjs";

import http from "@/http";

import { mdiDelete } from "@mdi/js";

export interface DataType {
  memo: string;
}

export default Vue.extend({
  props: {
    agreement: {
      type: Object as PropType<Agreement>,
      required: true
    }
  },
  data(): DataType {
    return {
      memo: ""
    };
  },
  computed: {
    sorted(): Memo[] {
      const memos = [...this.agreement.memos];
      return memos.sort((a, b) => {
        return dayjs(b.created_at).unix() - dayjs(a.created_at).unix();
      });
    },
    valid(): boolean {
      return this.memo.length > 0;
    },
    icon() {
      return { mdiDelete };
    }
  },
  methods: {
    async click() {
      const url = `agreements/${this.agreement.id}/memos`;

      const { data } = await http.post<Memo>(url, {
        content: this.memo
      });

      this.memo = "";

      this.agreement.memos.push(data);
    },
    async remove(memo: Memo) {
      if (window.confirm("本当に削除しますか")) {
        const url = `memos/${memo.id}`;

        await http.delete(url, {});

        this.agreement.memos = this.agreement.memos.filter(item => {
          return item.id !== memo.id;
        });
      }
    }
  }
});
